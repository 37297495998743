import { Stack } from '@mui/material'
import RecipeCard from '../../../components/RecipeCard'
import { useRecipesList } from '../../../generated/hook'

const RecipeList = () => {
  const { data, isLoading } = useRecipesList()

  if (!data || isLoading) {
    return null
  }

  const recipes = data

  return (
    <Stack gap={2} direction="row" flexWrap="wrap" justifyContent="center">
      {recipes.map((recipe) => (
        <RecipeCard
          key={`RecipeList-RecipeCard-${recipe.id}`}
          recipe={recipe}
        />
      ))}
    </Stack>
  )
}

export default RecipeList
