/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Your API
 * API description
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Accommodation,
  Category,
  CategoryBody,
  CookingTool,
  CookingToolBody,
  Image,
  ImageBody,
  Ingredient,
  Instruction,
  InstructionBody,
  Recipe,
  RecipesListParams,
  RecipeBody,
  Unit,
  UnitBody
} from './model'
import { customInstance } from '../services/network';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;




export const accommodationsList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Accommodation[]>(
      {url: `/accommodations/`, method: 'get', signal
    },
      );
    }
  

export const getAccommodationsListQueryKey = () => [`/accommodations/`] as const;
  

    
export const getAccommodationsListQueryOptions = <TData = Awaited<ReturnType<typeof accommodationsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accommodationsList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof accommodationsList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccommodationsListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof accommodationsList>>> = ({ signal }) => accommodationsList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AccommodationsListQueryResult = NonNullable<Awaited<ReturnType<typeof accommodationsList>>>
export type AccommodationsListQueryError = unknown

export const useAccommodationsList = <TData = Awaited<ReturnType<typeof accommodationsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accommodationsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccommodationsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const accommodationsCreate = (
    accommodation: NonReadonly<Accommodation>,
 ) => {
      return customInstance<Accommodation>(
      {url: `/accommodations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accommodation
    },
      );
    }
  


export const getAccommodationsCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accommodationsCreate>>, TError,{data: NonReadonly<Accommodation>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof accommodationsCreate>>, TError,{data: NonReadonly<Accommodation>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accommodationsCreate>>, {data: NonReadonly<Accommodation>}> = (props) => {
          const {data} = props ?? {};

          return  accommodationsCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AccommodationsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof accommodationsCreate>>>
    export type AccommodationsCreateMutationBody = NonReadonly<Accommodation>
    export type AccommodationsCreateMutationError = unknown

    export const useAccommodationsCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accommodationsCreate>>, TError,{data: NonReadonly<Accommodation>}, TContext>, }
) => {
    
      const mutationOptions = getAccommodationsCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const categoriesList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Category[]>(
      {url: `/categories/`, method: 'get', signal
    },
      );
    }
  

export const getCategoriesListQueryKey = () => [`/categories/`] as const;
  

    
export const getCategoriesListQueryOptions = <TData = Awaited<ReturnType<typeof categoriesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof categoriesList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCategoriesListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof categoriesList>>> = ({ signal }) => categoriesList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CategoriesListQueryResult = NonNullable<Awaited<ReturnType<typeof categoriesList>>>
export type CategoriesListQueryError = unknown

export const useCategoriesList = <TData = Awaited<ReturnType<typeof categoriesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCategoriesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const categoriesCreate = (
    categoryBody: CategoryBody,
 ) => {
      return customInstance<Category>(
      {url: `/categories/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: categoryBody
    },
      );
    }
  


export const getCategoriesCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesCreate>>, TError,{data: CategoryBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof categoriesCreate>>, TError,{data: CategoryBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof categoriesCreate>>, {data: CategoryBody}> = (props) => {
          const {data} = props ?? {};

          return  categoriesCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CategoriesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof categoriesCreate>>>
    export type CategoriesCreateMutationBody = CategoryBody
    export type CategoriesCreateMutationError = unknown

    export const useCategoriesCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesCreate>>, TError,{data: CategoryBody}, TContext>, }
) => {
    
      const mutationOptions = getCategoriesCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const categoriesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Category>(
      {url: `/categories/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getCategoriesReadQueryKey = (id: string,) => [`/categories/${id}/`] as const;
  

    
export const getCategoriesReadQueryOptions = <TData = Awaited<ReturnType<typeof categoriesRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof categoriesRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCategoriesReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof categoriesRead>>> = ({ signal }) => categoriesRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type CategoriesReadQueryResult = NonNullable<Awaited<ReturnType<typeof categoriesRead>>>
export type CategoriesReadQueryError = unknown

export const useCategoriesRead = <TData = Awaited<ReturnType<typeof categoriesRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCategoriesReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const categoriesUpdate = (
    id: string,
    categoryBody: CategoryBody,
 ) => {
      return customInstance<Category>(
      {url: `/categories/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: categoryBody
    },
      );
    }
  


export const getCategoriesUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesUpdate>>, TError,{id: string;data: CategoryBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof categoriesUpdate>>, TError,{id: string;data: CategoryBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof categoriesUpdate>>, {id: string;data: CategoryBody}> = (props) => {
          const {id,data} = props ?? {};

          return  categoriesUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CategoriesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof categoriesUpdate>>>
    export type CategoriesUpdateMutationBody = CategoryBody
    export type CategoriesUpdateMutationError = unknown

    export const useCategoriesUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesUpdate>>, TError,{id: string;data: CategoryBody}, TContext>, }
) => {
    
      const mutationOptions = getCategoriesUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const categoriesPartialUpdate = (
    id: string,
    categoryBody: CategoryBody,
 ) => {
      return customInstance<Category>(
      {url: `/categories/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: categoryBody
    },
      );
    }
  


export const getCategoriesPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesPartialUpdate>>, TError,{id: string;data: CategoryBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof categoriesPartialUpdate>>, TError,{id: string;data: CategoryBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof categoriesPartialUpdate>>, {id: string;data: CategoryBody}> = (props) => {
          const {id,data} = props ?? {};

          return  categoriesPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CategoriesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof categoriesPartialUpdate>>>
    export type CategoriesPartialUpdateMutationBody = CategoryBody
    export type CategoriesPartialUpdateMutationError = unknown

    export const useCategoriesPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesPartialUpdate>>, TError,{id: string;data: CategoryBody}, TContext>, }
) => {
    
      const mutationOptions = getCategoriesPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const categoriesDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/categories/${id}/`, method: 'delete'
    },
      );
    }
  


export const getCategoriesDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof categoriesDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof categoriesDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  categoriesDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CategoriesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof categoriesDelete>>>
    
    export type CategoriesDeleteMutationError = unknown

    export const useCategoriesDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof categoriesDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getCategoriesDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const cookingToolsList = (
    
 signal?: AbortSignal
) => {
      return customInstance<CookingTool[]>(
      {url: `/cooking-tools/`, method: 'get', signal
    },
      );
    }
  

export const getCookingToolsListQueryKey = () => [`/cooking-tools/`] as const;
  

    
export const getCookingToolsListQueryOptions = <TData = Awaited<ReturnType<typeof cookingToolsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cookingToolsList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof cookingToolsList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCookingToolsListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cookingToolsList>>> = ({ signal }) => cookingToolsList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CookingToolsListQueryResult = NonNullable<Awaited<ReturnType<typeof cookingToolsList>>>
export type CookingToolsListQueryError = unknown

export const useCookingToolsList = <TData = Awaited<ReturnType<typeof cookingToolsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cookingToolsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCookingToolsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const cookingToolsCreate = (
    cookingToolBody: CookingToolBody,
 ) => {
      return customInstance<CookingTool>(
      {url: `/cooking-tools/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cookingToolBody
    },
      );
    }
  


export const getCookingToolsCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsCreate>>, TError,{data: CookingToolBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cookingToolsCreate>>, TError,{data: CookingToolBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cookingToolsCreate>>, {data: CookingToolBody}> = (props) => {
          const {data} = props ?? {};

          return  cookingToolsCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CookingToolsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof cookingToolsCreate>>>
    export type CookingToolsCreateMutationBody = CookingToolBody
    export type CookingToolsCreateMutationError = unknown

    export const useCookingToolsCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsCreate>>, TError,{data: CookingToolBody}, TContext>, }
) => {
    
      const mutationOptions = getCookingToolsCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const cookingToolsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<CookingTool>(
      {url: `/cooking-tools/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getCookingToolsReadQueryKey = (id: string,) => [`/cooking-tools/${id}/`] as const;
  

    
export const getCookingToolsReadQueryOptions = <TData = Awaited<ReturnType<typeof cookingToolsRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cookingToolsRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof cookingToolsRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCookingToolsReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cookingToolsRead>>> = ({ signal }) => cookingToolsRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type CookingToolsReadQueryResult = NonNullable<Awaited<ReturnType<typeof cookingToolsRead>>>
export type CookingToolsReadQueryError = unknown

export const useCookingToolsRead = <TData = Awaited<ReturnType<typeof cookingToolsRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cookingToolsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCookingToolsReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const cookingToolsUpdate = (
    id: string,
    cookingToolBody: CookingToolBody,
 ) => {
      return customInstance<CookingTool>(
      {url: `/cooking-tools/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: cookingToolBody
    },
      );
    }
  


export const getCookingToolsUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsUpdate>>, TError,{id: string;data: CookingToolBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cookingToolsUpdate>>, TError,{id: string;data: CookingToolBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cookingToolsUpdate>>, {id: string;data: CookingToolBody}> = (props) => {
          const {id,data} = props ?? {};

          return  cookingToolsUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CookingToolsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof cookingToolsUpdate>>>
    export type CookingToolsUpdateMutationBody = CookingToolBody
    export type CookingToolsUpdateMutationError = unknown

    export const useCookingToolsUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsUpdate>>, TError,{id: string;data: CookingToolBody}, TContext>, }
) => {
    
      const mutationOptions = getCookingToolsUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const cookingToolsPartialUpdate = (
    id: string,
    cookingToolBody: CookingToolBody,
 ) => {
      return customInstance<CookingTool>(
      {url: `/cooking-tools/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: cookingToolBody
    },
      );
    }
  


export const getCookingToolsPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsPartialUpdate>>, TError,{id: string;data: CookingToolBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cookingToolsPartialUpdate>>, TError,{id: string;data: CookingToolBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cookingToolsPartialUpdate>>, {id: string;data: CookingToolBody}> = (props) => {
          const {id,data} = props ?? {};

          return  cookingToolsPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CookingToolsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof cookingToolsPartialUpdate>>>
    export type CookingToolsPartialUpdateMutationBody = CookingToolBody
    export type CookingToolsPartialUpdateMutationError = unknown

    export const useCookingToolsPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsPartialUpdate>>, TError,{id: string;data: CookingToolBody}, TContext>, }
) => {
    
      const mutationOptions = getCookingToolsPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const cookingToolsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/cooking-tools/${id}/`, method: 'delete'
    },
      );
    }
  


export const getCookingToolsDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cookingToolsDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cookingToolsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  cookingToolsDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CookingToolsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof cookingToolsDelete>>>
    
    export type CookingToolsDeleteMutationError = unknown

    export const useCookingToolsDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cookingToolsDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getCookingToolsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const imagesList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Image[]>(
      {url: `/images/`, method: 'get', signal
    },
      );
    }
  

export const getImagesListQueryKey = () => [`/images/`] as const;
  

    
export const getImagesListQueryOptions = <TData = Awaited<ReturnType<typeof imagesList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof imagesList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof imagesList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getImagesListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof imagesList>>> = ({ signal }) => imagesList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ImagesListQueryResult = NonNullable<Awaited<ReturnType<typeof imagesList>>>
export type ImagesListQueryError = unknown

export const useImagesList = <TData = Awaited<ReturnType<typeof imagesList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof imagesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getImagesListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const imagesCreate = (
    imageBody: ImageBody,
 ) => {
      return customInstance<Image>(
      {url: `/images/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: imageBody
    },
      );
    }
  


export const getImagesCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesCreate>>, TError,{data: ImageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof imagesCreate>>, TError,{data: ImageBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof imagesCreate>>, {data: ImageBody}> = (props) => {
          const {data} = props ?? {};

          return  imagesCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ImagesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof imagesCreate>>>
    export type ImagesCreateMutationBody = ImageBody
    export type ImagesCreateMutationError = unknown

    export const useImagesCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesCreate>>, TError,{data: ImageBody}, TContext>, }
) => {
    
      const mutationOptions = getImagesCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const imagesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Image>(
      {url: `/images/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getImagesReadQueryKey = (id: string,) => [`/images/${id}/`] as const;
  

    
export const getImagesReadQueryOptions = <TData = Awaited<ReturnType<typeof imagesRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof imagesRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof imagesRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getImagesReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof imagesRead>>> = ({ signal }) => imagesRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type ImagesReadQueryResult = NonNullable<Awaited<ReturnType<typeof imagesRead>>>
export type ImagesReadQueryError = unknown

export const useImagesRead = <TData = Awaited<ReturnType<typeof imagesRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof imagesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getImagesReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const imagesUpdate = (
    id: string,
    imageBody: ImageBody,
 ) => {
      return customInstance<Image>(
      {url: `/images/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: imageBody
    },
      );
    }
  


export const getImagesUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesUpdate>>, TError,{id: string;data: ImageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof imagesUpdate>>, TError,{id: string;data: ImageBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof imagesUpdate>>, {id: string;data: ImageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  imagesUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ImagesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof imagesUpdate>>>
    export type ImagesUpdateMutationBody = ImageBody
    export type ImagesUpdateMutationError = unknown

    export const useImagesUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesUpdate>>, TError,{id: string;data: ImageBody}, TContext>, }
) => {
    
      const mutationOptions = getImagesUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const imagesPartialUpdate = (
    id: string,
    imageBody: ImageBody,
 ) => {
      return customInstance<Image>(
      {url: `/images/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: imageBody
    },
      );
    }
  


export const getImagesPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesPartialUpdate>>, TError,{id: string;data: ImageBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof imagesPartialUpdate>>, TError,{id: string;data: ImageBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof imagesPartialUpdate>>, {id: string;data: ImageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  imagesPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ImagesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof imagesPartialUpdate>>>
    export type ImagesPartialUpdateMutationBody = ImageBody
    export type ImagesPartialUpdateMutationError = unknown

    export const useImagesPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesPartialUpdate>>, TError,{id: string;data: ImageBody}, TContext>, }
) => {
    
      const mutationOptions = getImagesPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const imagesDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/images/${id}/`, method: 'delete'
    },
      );
    }
  


export const getImagesDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof imagesDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof imagesDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  imagesDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ImagesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof imagesDelete>>>
    
    export type ImagesDeleteMutationError = unknown

    export const useImagesDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof imagesDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getImagesDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const ingredientsList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Ingredient[]>(
      {url: `/ingredients/`, method: 'get', signal
    },
      );
    }
  

export const getIngredientsListQueryKey = () => [`/ingredients/`] as const;
  

    
export const getIngredientsListQueryOptions = <TData = Awaited<ReturnType<typeof ingredientsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof ingredientsList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof ingredientsList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIngredientsListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof ingredientsList>>> = ({ signal }) => ingredientsList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type IngredientsListQueryResult = NonNullable<Awaited<ReturnType<typeof ingredientsList>>>
export type IngredientsListQueryError = unknown

export const useIngredientsList = <TData = Awaited<ReturnType<typeof ingredientsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof ingredientsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIngredientsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const ingredientsCreate = (
    ingredient: NonReadonly<Ingredient>,
 ) => {
      return customInstance<Ingredient>(
      {url: `/ingredients/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: ingredient
    },
      );
    }
  


export const getIngredientsCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingredientsCreate>>, TError,{data: NonReadonly<Ingredient>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof ingredientsCreate>>, TError,{data: NonReadonly<Ingredient>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof ingredientsCreate>>, {data: NonReadonly<Ingredient>}> = (props) => {
          const {data} = props ?? {};

          return  ingredientsCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type IngredientsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ingredientsCreate>>>
    export type IngredientsCreateMutationBody = NonReadonly<Ingredient>
    export type IngredientsCreateMutationError = unknown

    export const useIngredientsCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof ingredientsCreate>>, TError,{data: NonReadonly<Ingredient>}, TContext>, }
) => {
    
      const mutationOptions = getIngredientsCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const instructionsList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Instruction[]>(
      {url: `/instructions/`, method: 'get', signal
    },
      );
    }
  

export const getInstructionsListQueryKey = () => [`/instructions/`] as const;
  

    
export const getInstructionsListQueryOptions = <TData = Awaited<ReturnType<typeof instructionsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof instructionsList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof instructionsList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInstructionsListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof instructionsList>>> = ({ signal }) => instructionsList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type InstructionsListQueryResult = NonNullable<Awaited<ReturnType<typeof instructionsList>>>
export type InstructionsListQueryError = unknown

export const useInstructionsList = <TData = Awaited<ReturnType<typeof instructionsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof instructionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInstructionsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const instructionsCreate = (
    instructionBody: InstructionBody,
 ) => {
      return customInstance<Instruction>(
      {url: `/instructions/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: instructionBody
    },
      );
    }
  


export const getInstructionsCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsCreate>>, TError,{data: InstructionBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof instructionsCreate>>, TError,{data: InstructionBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof instructionsCreate>>, {data: InstructionBody}> = (props) => {
          const {data} = props ?? {};

          return  instructionsCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InstructionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof instructionsCreate>>>
    export type InstructionsCreateMutationBody = InstructionBody
    export type InstructionsCreateMutationError = unknown

    export const useInstructionsCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsCreate>>, TError,{data: InstructionBody}, TContext>, }
) => {
    
      const mutationOptions = getInstructionsCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const instructionsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Instruction>(
      {url: `/instructions/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getInstructionsReadQueryKey = (id: string,) => [`/instructions/${id}/`] as const;
  

    
export const getInstructionsReadQueryOptions = <TData = Awaited<ReturnType<typeof instructionsRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof instructionsRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof instructionsRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInstructionsReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof instructionsRead>>> = ({ signal }) => instructionsRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type InstructionsReadQueryResult = NonNullable<Awaited<ReturnType<typeof instructionsRead>>>
export type InstructionsReadQueryError = unknown

export const useInstructionsRead = <TData = Awaited<ReturnType<typeof instructionsRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof instructionsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInstructionsReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const instructionsUpdate = (
    id: string,
    instructionBody: InstructionBody,
 ) => {
      return customInstance<Instruction>(
      {url: `/instructions/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: instructionBody
    },
      );
    }
  


export const getInstructionsUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsUpdate>>, TError,{id: string;data: InstructionBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof instructionsUpdate>>, TError,{id: string;data: InstructionBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof instructionsUpdate>>, {id: string;data: InstructionBody}> = (props) => {
          const {id,data} = props ?? {};

          return  instructionsUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InstructionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof instructionsUpdate>>>
    export type InstructionsUpdateMutationBody = InstructionBody
    export type InstructionsUpdateMutationError = unknown

    export const useInstructionsUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsUpdate>>, TError,{id: string;data: InstructionBody}, TContext>, }
) => {
    
      const mutationOptions = getInstructionsUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const instructionsPartialUpdate = (
    id: string,
    instructionBody: InstructionBody,
 ) => {
      return customInstance<Instruction>(
      {url: `/instructions/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: instructionBody
    },
      );
    }
  


export const getInstructionsPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsPartialUpdate>>, TError,{id: string;data: InstructionBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof instructionsPartialUpdate>>, TError,{id: string;data: InstructionBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof instructionsPartialUpdate>>, {id: string;data: InstructionBody}> = (props) => {
          const {id,data} = props ?? {};

          return  instructionsPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InstructionsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof instructionsPartialUpdate>>>
    export type InstructionsPartialUpdateMutationBody = InstructionBody
    export type InstructionsPartialUpdateMutationError = unknown

    export const useInstructionsPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsPartialUpdate>>, TError,{id: string;data: InstructionBody}, TContext>, }
) => {
    
      const mutationOptions = getInstructionsPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const instructionsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/instructions/${id}/`, method: 'delete'
    },
      );
    }
  


export const getInstructionsDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof instructionsDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof instructionsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  instructionsDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InstructionsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof instructionsDelete>>>
    
    export type InstructionsDeleteMutationError = unknown

    export const useInstructionsDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof instructionsDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getInstructionsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const recipesList = (
    params?: RecipesListParams,
 signal?: AbortSignal
) => {
      return customInstance<Recipe[]>(
      {url: `/recipes/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRecipesListQueryKey = (params?: RecipesListParams,) => [`/recipes/`, ...(params ? [params]: [])] as const;
  

    
export const getRecipesListQueryOptions = <TData = Awaited<ReturnType<typeof recipesList>>, TError = unknown>(params?: RecipesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recipesList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof recipesList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRecipesListQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof recipesList>>> = ({ signal }) => recipesList(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type RecipesListQueryResult = NonNullable<Awaited<ReturnType<typeof recipesList>>>
export type RecipesListQueryError = unknown

export const useRecipesList = <TData = Awaited<ReturnType<typeof recipesList>>, TError = unknown>(
 params?: RecipesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recipesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRecipesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const recipesCreate = (
    recipeBody: RecipeBody,
 ) => {
      return customInstance<Recipe>(
      {url: `/recipes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recipeBody
    },
      );
    }
  


export const getRecipesCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesCreate>>, TError,{data: RecipeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof recipesCreate>>, TError,{data: RecipeBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recipesCreate>>, {data: RecipeBody}> = (props) => {
          const {data} = props ?? {};

          return  recipesCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RecipesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof recipesCreate>>>
    export type RecipesCreateMutationBody = RecipeBody
    export type RecipesCreateMutationError = unknown

    export const useRecipesCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesCreate>>, TError,{data: RecipeBody}, TContext>, }
) => {
    
      const mutationOptions = getRecipesCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const recipesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Recipe>(
      {url: `/recipes/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getRecipesReadQueryKey = (id: string,) => [`/recipes/${id}/`] as const;
  

    
export const getRecipesReadQueryOptions = <TData = Awaited<ReturnType<typeof recipesRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recipesRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof recipesRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRecipesReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof recipesRead>>> = ({ signal }) => recipesRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type RecipesReadQueryResult = NonNullable<Awaited<ReturnType<typeof recipesRead>>>
export type RecipesReadQueryError = unknown

export const useRecipesRead = <TData = Awaited<ReturnType<typeof recipesRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recipesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRecipesReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const recipesUpdate = (
    id: string,
    recipeBody: RecipeBody,
 ) => {
      return customInstance<Recipe>(
      {url: `/recipes/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: recipeBody
    },
      );
    }
  


export const getRecipesUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesUpdate>>, TError,{id: string;data: RecipeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof recipesUpdate>>, TError,{id: string;data: RecipeBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recipesUpdate>>, {id: string;data: RecipeBody}> = (props) => {
          const {id,data} = props ?? {};

          return  recipesUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RecipesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof recipesUpdate>>>
    export type RecipesUpdateMutationBody = RecipeBody
    export type RecipesUpdateMutationError = unknown

    export const useRecipesUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesUpdate>>, TError,{id: string;data: RecipeBody}, TContext>, }
) => {
    
      const mutationOptions = getRecipesUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const recipesPartialUpdate = (
    id: string,
    recipeBody: RecipeBody,
 ) => {
      return customInstance<Recipe>(
      {url: `/recipes/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: recipeBody
    },
      );
    }
  


export const getRecipesPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesPartialUpdate>>, TError,{id: string;data: RecipeBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof recipesPartialUpdate>>, TError,{id: string;data: RecipeBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recipesPartialUpdate>>, {id: string;data: RecipeBody}> = (props) => {
          const {id,data} = props ?? {};

          return  recipesPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RecipesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof recipesPartialUpdate>>>
    export type RecipesPartialUpdateMutationBody = RecipeBody
    export type RecipesPartialUpdateMutationError = unknown

    export const useRecipesPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesPartialUpdate>>, TError,{id: string;data: RecipeBody}, TContext>, }
) => {
    
      const mutationOptions = getRecipesPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const recipesDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/recipes/${id}/`, method: 'delete'
    },
      );
    }
  


export const getRecipesDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof recipesDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recipesDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  recipesDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RecipesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof recipesDelete>>>
    
    export type RecipesDeleteMutationError = unknown

    export const useRecipesDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recipesDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getRecipesDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const unitsList = (
    
 signal?: AbortSignal
) => {
      return customInstance<Unit[]>(
      {url: `/units/`, method: 'get', signal
    },
      );
    }
  

export const getUnitsListQueryKey = () => [`/units/`] as const;
  

    
export const getUnitsListQueryOptions = <TData = Awaited<ReturnType<typeof unitsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof unitsList>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof unitsList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUnitsListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof unitsList>>> = ({ signal }) => unitsList(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UnitsListQueryResult = NonNullable<Awaited<ReturnType<typeof unitsList>>>
export type UnitsListQueryError = unknown

export const useUnitsList = <TData = Awaited<ReturnType<typeof unitsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof unitsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUnitsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const unitsCreate = (
    unitBody: UnitBody,
 ) => {
      return customInstance<Unit>(
      {url: `/units/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: unitBody
    },
      );
    }
  


export const getUnitsCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsCreate>>, TError,{data: UnitBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unitsCreate>>, TError,{data: UnitBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unitsCreate>>, {data: UnitBody}> = (props) => {
          const {data} = props ?? {};

          return  unitsCreate(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UnitsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof unitsCreate>>>
    export type UnitsCreateMutationBody = UnitBody
    export type UnitsCreateMutationError = unknown

    export const useUnitsCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsCreate>>, TError,{data: UnitBody}, TContext>, }
) => {
    
      const mutationOptions = getUnitsCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const unitsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Unit>(
      {url: `/units/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getUnitsReadQueryKey = (id: string,) => [`/units/${id}/`] as const;
  

    
export const getUnitsReadQueryOptions = <TData = Awaited<ReturnType<typeof unitsRead>>, TError = unknown>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof unitsRead>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof unitsRead>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUnitsReadQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof unitsRead>>> = ({ signal }) => unitsRead(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type UnitsReadQueryResult = NonNullable<Awaited<ReturnType<typeof unitsRead>>>
export type UnitsReadQueryError = unknown

export const useUnitsRead = <TData = Awaited<ReturnType<typeof unitsRead>>, TError = unknown>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof unitsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUnitsReadQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const unitsUpdate = (
    id: string,
    unitBody: UnitBody,
 ) => {
      return customInstance<Unit>(
      {url: `/units/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: unitBody
    },
      );
    }
  


export const getUnitsUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsUpdate>>, TError,{id: string;data: UnitBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unitsUpdate>>, TError,{id: string;data: UnitBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unitsUpdate>>, {id: string;data: UnitBody}> = (props) => {
          const {id,data} = props ?? {};

          return  unitsUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UnitsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof unitsUpdate>>>
    export type UnitsUpdateMutationBody = UnitBody
    export type UnitsUpdateMutationError = unknown

    export const useUnitsUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsUpdate>>, TError,{id: string;data: UnitBody}, TContext>, }
) => {
    
      const mutationOptions = getUnitsUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const unitsPartialUpdate = (
    id: string,
    unitBody: UnitBody,
 ) => {
      return customInstance<Unit>(
      {url: `/units/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: unitBody
    },
      );
    }
  


export const getUnitsPartialUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsPartialUpdate>>, TError,{id: string;data: UnitBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unitsPartialUpdate>>, TError,{id: string;data: UnitBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unitsPartialUpdate>>, {id: string;data: UnitBody}> = (props) => {
          const {id,data} = props ?? {};

          return  unitsPartialUpdate(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UnitsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof unitsPartialUpdate>>>
    export type UnitsPartialUpdateMutationBody = UnitBody
    export type UnitsPartialUpdateMutationError = unknown

    export const useUnitsPartialUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsPartialUpdate>>, TError,{id: string;data: UnitBody}, TContext>, }
) => {
    
      const mutationOptions = getUnitsPartialUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const unitsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/units/${id}/`, method: 'delete'
    },
      );
    }
  


export const getUnitsDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsDelete>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof unitsDelete>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unitsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  unitsDelete(id,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UnitsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof unitsDelete>>>
    
    export type UnitsDeleteMutationError = unknown

    export const useUnitsDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unitsDelete>>, TError,{id: string}, TContext>, }
) => {
    
      const mutationOptions = getUnitsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
