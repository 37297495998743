import { createTheme } from '@mui/material'

export const muiTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '12px',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px',
          },
        },
      },
    },
  },
})

muiTheme.typography.h1 = {
  ...muiTheme.typography.h1,
  [muiTheme.breakpoints.up('xs')]: {
    fontSize: '20px',
  },
  [muiTheme.breakpoints.up('sm')]: {
    fontSize: '28px',
  },
}

muiTheme.typography.h4 = {
  ...muiTheme.typography.h4,
  [muiTheme.breakpoints.up('xs')]: {
    fontSize: '16px',
  },
  [muiTheme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}
