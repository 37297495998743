import { Card, Paper, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { Recipe } from '../../generated/model'

interface Props {
  recipe: Recipe
}

export const RecipeCard = ({ recipe }: Props) => {
  return (
    <Link to={`/recipes/${recipe.id}`}>
      <Paper elevation={0}>
        <Stack>
          <Card
            sx={{ position: 'relative', maxHeight: '157px', maxWidth: '256px' }}
          >
            <img
              alt={recipe.mainPicture?.name || 'altPlaceholder'}
              src={recipe.mainPicture?.url}
              style={{ height: '100%', width: '100%' }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: '8px',
                left: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                color: 'white',
                padding: '8px',
                textTransform: 'capitalize',
              }}
            >
              {recipe.category?.name || 'categoryPlaceholder'}
            </div>
          </Card>
          {recipe.name}
          <Stack direction="row" gap={1}>
            <div>{recipe.rating}</div>
            <div>sur</div>
            <div>{recipe.ratingCount}</div>
            <div>avis</div>
          </Stack>
        </Stack>
      </Paper>
    </Link>
  )
}

export default RecipeCard
