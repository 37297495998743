interface GenericObject {
  id: string
  [key: string]: any
}

export const mapById = <T extends GenericObject>(
  arr: T[],
): { [key: string]: T } => {
  return arr.reduce(
    (acc, cur) => {
      acc[cur.id] = cur
      return acc
    },
    {} as { [key: string]: T },
  )
}

export const getRandomArray = (min: number, max: number): number[] => {
  // Ensure valid input
  if (min < 0 || max < 0 || max < min) {
    throw new Error('Invalid min or max values')
  }

  // Generate random array length
  const length = Math.floor(Math.random() * (max - min + 1)) + min

  // Generate array of random numbers
  const arr: number[] = Array.from(
    { length },
    () => Math.floor(Math.random() * max) + min,
  )

  return arr
}

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  wait: number,
): ((...args: Parameters<F>) => void) => {
  let timeout: NodeJS.Timeout | null = null

  return (...args: Parameters<F>): void => {
    const later = () => {
      timeout = null
      func(...args)
    }

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(later, wait)
  }
}
