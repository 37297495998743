import { Card, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { CookingToolInRecipe } from '../../generated/model'

interface Props {
  cookingToolInRecipe: CookingToolInRecipe
}

export const CookingToolInRecipeCard = ({ cookingToolInRecipe }: Props) => {
  return (
    <Link to={`/cooking-tools/${cookingToolInRecipe.cookingTool.id}`}>
      <Paper elevation={0}>
        <Stack>
          {cookingToolInRecipe.cookingTool.mainPicture && (
            <Card
              sx={{
                position: 'relative',
                maxHeight: '157px',
                maxWidth: '256px',
              }}
            >
              <img
                alt={cookingToolInRecipe.cookingTool.name}
                src={cookingToolInRecipe.cookingTool.mainPicture.url}
                style={{ height: '100%', width: '100%' }}
              />
            </Card>
          )}
          <Stack direction="row" gap={1}>
            <Typography variant="body1">
              {cookingToolInRecipe.quantity}
            </Typography>
            <Typography variant="body1">
              {cookingToolInRecipe.cookingTool.name}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Link>
  )
}

export default CookingToolInRecipeCard
