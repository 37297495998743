import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'

import Layout from './components/Layout'
import HomePage from './pages/HomePage'
import RecipeDetailPage from './pages/RecipeDetailPage'
import { ROUTES } from './routes'
import { queryClient } from './services/reactQuery'
import { ThemeProvider } from '@mui/material'
import { muiTheme } from './theme'

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
              <Route
                path={ROUTES.RECIPE_DETAIL_PAGE}
                element={<RecipeDetailPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
