import { useParams } from 'react-router-dom'

import RecipeDetails from './RecipeDetails'

export const RecipeDetailPage = () => {
  const { recipeId } = useParams()

  if (!recipeId) {
    return null
  }

  return <RecipeDetails recipeId={recipeId} />
}

export default RecipeDetailPage
