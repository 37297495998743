import { Category } from '../models'
import { mapById } from '../services/notRamda'

export const CATEGORY_FIXTURES: Array<Category> = [
  {
    id: 'a3b946e5-ffed-4850-8f0c-2e504e993761',
    name: 'Start',
  },
  {
    id: 'a623907f-9c07-48b7-ae2a-6accd82a0fc2',
    name: 'Advice',
  },
  {
    id: '0f004b3b-7555-408c-877a-ab62677a28a3',
    name: 'Main',
  },
  {
    id: '8b4bef53-97be-4442-894b-81a7147fe154',
    name: 'Dessert',
  },
]

export const CATEGORY_FIXTURES_BY_ID = mapById(CATEGORY_FIXTURES)
