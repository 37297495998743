import { Card, Divider, Stack, Typography } from '@mui/material'
import KitchenIcon from '@mui/icons-material/Kitchen'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'
import LocalDiningIcon from '@mui/icons-material/LocalDining'

import { useRecipesRead } from '../../../generated/hook'
import IngredientInRecipeCard from '../../../components/IngredientInRecipeCard'
import CookingToolInRecipeCard from '../../../components/CookingToolCardInRecipeCard'

interface Props {
  recipeId: string
}

const RecipeDetails = (props: Props) => {
  const { recipeId } = props
  const { data, isLoading } = useRecipesRead(recipeId)

  if (!data || isLoading) {
    return null
  }

  const recipe = data

  return (
    <Stack>
      <Typography variant="h1">{recipe.name}</Typography>
      <Stack gap={2}>
        {recipe.mainPicture && (
          <img
            src={recipe.mainPicture.url}
            alt={recipe.mainPicture.name || 'Image de la recette'}
            style={{
              width: '100%',
              height: '450px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        )}
        <Stack direction="row" sx={{ height: '145px' }} gap={2}>
          {recipe.pictures &&
            recipe.pictures.map((picture, index) => (
              <Card key={`RecipeDetailPage-Picture-${index}`} sx={{ flex: 1 }}>
                <img
                  src={picture.url}
                  alt={`RecipeDetails-RecipePicture-${index}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </Card>
            ))}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <KitchenIcon sx={{ fontSize: '24px' }} />
        <Typography variant="h2">Ingrédients</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <Stack direction="row" gap={2} flexWrap="wrap">
        {recipe.ingredients.map((ingredientInRecipe, index) => {
          return (
            <IngredientInRecipeCard
              key={`RecipeDetails-Ingredient-${
                ingredientInRecipe.ingredient.id || index
              }`}
              ingredientInRecipe={ingredientInRecipe}
            />
          )
        })}
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <SoupKitchenIcon sx={{ fontSize: '24px' }} />
        <Typography variant="h2">Ustensils</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <Stack direction="row" gap={2} flexWrap="wrap">
        {recipe.cookingTools &&
          recipe.cookingTools.map((cookingToolInRecipe) => (
            <CookingToolInRecipeCard
              key={`RecipeDetails-CookingTool${cookingToolInRecipe.cookingTool.id}`}
              cookingToolInRecipe={cookingToolInRecipe}
            />
          ))}
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <LocalDiningIcon sx={{ fontSize: '24px' }} />
        <Typography variant="h2">Étapes</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <Stack gap={2}>
        {recipe.instructions.map((step, index) => (
          <Stack key={`RecipeDetailPage-${recipe.id}-${index}`}>
            <Typography variant="h3">Etape {step.order}</Typography>
            <Typography variant="body1">
              {step.instruction.description}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default RecipeDetails
