import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import {
  AppBar,
  Autocomplete,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Link, Outlet } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { debounce } from '../../services/notRamda'
import { recipesList } from '../../generated/hook'
import { useState } from 'react'
import { Recipe } from '../../generated/model'

export const Layout = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [charCountInAutocomplete, setCharCountInAutocomplete] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const getRecipesBySearch = async (search: string) => {
    if (!search) {
      setIsLoading(false)
      setRecipes([])
      return Promise.resolve()
    }

    const recipesFound = await recipesList({ search })
    setIsLoading(false)
    setRecipes(recipesFound)
  }
  const debouncedGetRecipesByName = debounce(getRecipesBySearch, 500)

  return (
    <Stack>
      <AppBar position="sticky" sx={{ p: 2, backgroundColor: 'white' }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Link to={ROUTES.HOME_PAGE}>
            <Stack direction="row" gap={1}>
              <ThumbUpIcon color="primary" />
              <Typography variant="body1" color="primary">
                Not Marmiton
              </Typography>
            </Stack>
          </Link>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Autocomplete
              disablePortal
              options={charCountInAutocomplete === 0 ? [] : recipes}
              loading={isLoading}
              onInputChange={async (_, value) => {
                setCharCountInAutocomplete(value.length)
                setIsLoading(true)
                debouncedGetRecipesByName(value)
              }}
              noOptionsText={
                charCountInAutocomplete === 0
                  ? 'Type any characters to start searching...'
                  : 'No recipes found'
              }
              sx={{ flex: 1, width: '200px' }}
              id="combo-box-demo"
              size="small"
              color="secondary"
              autoHighlight
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                  <Link
                    key={`Layout-AutocompleteOption-${option.id}`}
                    to={`/recipes/${option.id}`}
                  >
                    <Box
                      component="li"
                      {...props}
                      sx={{
                        p: 2,
                      }}
                    >
                      {option.name}
                    </Box>
                  </Link>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="I search for a recipe, ingredient, help, ..."
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Stack>
      </AppBar>
      <Stack>
        <Container sx={{ backgroundColor: 'white', my: 2, py: 4 }}>
          <Outlet />
        </Container>
      </Stack>
    </Stack>
  )
}

export default Layout
