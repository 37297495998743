import { Card, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { IngredientInRecipe } from '../../generated/model'

interface Props {
  ingredientInRecipe: IngredientInRecipe
}

export const IngredientInRecipeCard = ({ ingredientInRecipe }: Props) => {
  return (
    <Link to={`/ingredients/${ingredientInRecipe.ingredient.id}`}>
      <Paper elevation={0}>
        <Stack>
          {ingredientInRecipe.ingredient.mainPicture && (
            <Card
              sx={{ position: 'relative', height: '157px', maxWidth: '256px' }}
            >
              <img
                alt={ingredientInRecipe.ingredient.name}
                src={ingredientInRecipe.ingredient.mainPicture.url}
                style={{ height: '100%', width: '100%' }}
              />
            </Card>
          )}
          <Stack direction="row" gap={1}>
            <Typography variant="body1">
              {ingredientInRecipe.quantity}
            </Typography>
            <Typography variant="body1">
              {ingredientInRecipe.unit?.name}
            </Typography>
            <Typography variant="body1">
              {ingredientInRecipe.ingredient.name}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Link>
  )
}

export default IngredientInRecipeCard
