import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import { Button, Divider, Stack } from '@mui/material'
import { useState } from 'react'

import { CATEGORY_FIXTURES } from 'fixtures/categories'
import { Category } from '../../models'
import RecipeList from './RecipeList'

export const HomePage = () => {
  const [filteredCategoryIds, setFilteredCategoryIds] = useState<Array<string>>(
    [],
  )

  const onClickFilterCategory = (category: Category) => {
    if (filteredCategoryIds.includes(category.id)) {
      console.log('woops')
      setFilteredCategoryIds(
        filteredCategoryIds.filter((id) => id !== category.id),
      )

      return
    }

    setFilteredCategoryIds([...filteredCategoryIds, category.id])
  }

  // const filteredRecipes = useMemo(() => {
  //   if (filteredCategoryIds.length === 0) {
  //     return RECIPES_FIXTURES;
  //   }

  //   return RECIPES_FIXTURES.filter((recipe) =>
  //     filteredCategoryIds.includes(recipe.category.id)
  //   );
  // }, [filteredCategoryIds]);

  return (
    <Stack gap={4}>
      <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="center">
        {CATEGORY_FIXTURES.map((category) => (
          <Button
            key={category.id}
            onClick={() => onClickFilterCategory(category)}
            variant={
              filteredCategoryIds.includes(category.id)
                ? 'contained'
                : 'outlined'
            }
          >
            {category.name}
          </Button>
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Divider sx={{ flex: 1 }} />
        <RestaurantMenuIcon />
        <Divider sx={{ flex: 1 }} />
      </Stack>
      <RecipeList />
    </Stack>
  )
}

export default HomePage
